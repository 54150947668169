import React from "react"
import Seo from "../components/SEO"
import StreamComponent from "../components/StreamComponent"
import useSiteMetadata from "../hooks/useSiteMetadata"

export default () => {
  return (
    <section className="py-[4.375rem]">
      <div className="container">
        <div className="title-sec m-auto w-full">
          <h2 className="lg:text-[2.5rem] md:text-[2.3rem] text-[2rem] pb-1 font-blancotrialbold lg:leading-[3.75rem] md:leading-[3rem] leading-[2.75rem] text-center">
            Thank you for your inquiry!
          </h2>
          <p className="text-base leading-[1.7rem] text-center text-grey pb-8">
            We'll contact you back within 24 hours to discuss your needs in
            detail.
          </p>
          <div className="pt-[1.375rem] relative flex justify-center">
            <StreamComponent />
          </div>
        </div>
      </div>
    </section>
  )
}
export const Head = ({ location }) => {
  const { siteUrl } = useSiteMetadata()
  return (
    <Seo
      title="Thank You | Techuz"
      canonical={`${siteUrl}${location.pathname}`}
      description="Thanks for visiting Techuz"
      noIndex
    />
  )
}
